
<template>
    <div class="steps">
        <div class="step-group">
            <div v-for="n in totalStep"
                :key="n" 
                :class="['step-item', step >= n ? 'active' : '']">
                {{n}}
            </div>    
        </div>
        
        <div class="steps-label"> 
            <div v-for="n in totalStep"
                :key="n" 
                :class="[step >= n ? 'active' : '']">
                <span></span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ContractSteps',
        props: {
            step: Number,
            totalStep: Number,
        },
        data() {
            return {

            }
        },
        methods: {

        },
    }
</script>

<style>
    .steps {
        margin: 20px auto;
        width: 530px;
    }

    .step-group {
        width: 550px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
    }

    .step-item {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        font-size: 50px;
        background: #999;
        text-align: center;
        line-height: 60px;
        color: #fff;
    }

    .step-item.active {
        background: #1ab394;
    }

    .steps-label {
        width: 550px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
        height: 20px;
    }

    .steps-label div {
        position: relative;
        flex: 1 0 auto;
        text-align: center;
        height: 20px;
    }

    .steps-label div:not(:first-of-type)::after {
        position: absolute;
        right: 50%;
        top: 50%;
        content: " ";
        transform: translateY(-50%);
        width: 128px;
        height: 4px;
        background: #D8D8D8;
        text-align: left;
    }

    .steps-label div span {
        display: inline-block;
        background: #D8D8D8;
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .steps-label div.active span, .steps-label div.active::after {
        background: #1ab394;
    }
</style>
            